import axios from 'axios';

const state = {
  _users: [],
};

const mutations = {
  SET_USERS: (state, users) => {
    state._users = users;
  },
};

const actions = {
  retrieveUsers: ({ commit }) => {
    return axios.get('/user-details').then(res => {
      if(res.status == 200 ) commit('SET_USERS', res.data);
      return res;
    }).catch((err) => console.log(err));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
