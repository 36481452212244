<template>
  <v-row
    class="fill-height"
    justify="center"
    align="center"
  >
    <v-col
      :lg="4"
      class="px-10 lg:px-0"
    >
      <v-card>
        <v-card-title>{{ COMMON.LOGIN_LABEL }}</v-card-title>
        <v-card-text v-loading="isBusy">
          <v-form
            ref="form"
            @submit.prevent="onSubmit"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="credentials.username"
                  solo
                  label="Username"
                  hide-details="auto"
                  type="text"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="credentials.password"
                  solo
                  label="Password"
                  hide-details="auto"
                  type="password"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12">
                <v-btn
                  block
                  class="primary"
                  type="submit"
                >
                  <span v-if="!isBusy">{{ COMMON.LOGIN_LABEL }}</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                  />
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-btn
                  text
                  @click="$router.push('/forgot-password')"
                >
                  Forgot Password?
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      absolute
      right
      top
    >
      {{ snackBarText }}
      <template #action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ COMMON.CLOSE_BTN }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import common from '@/commons/common';

export default {
  data() {
    return {
      COMMON: common,
      ICON: common.ICON,
      isBusy: false,

      snackbar: false,
      snackBarText: 'This is default text!',
      timeout: 3000,

      credentials: {
        username: '',
        password: '',
      },

      rules: {
        required: (value) => !!value || 'Required.',
      },
    };
  },

  methods: {
    enableSnackbar(text) {
      this.snackbar = true;
      this.snackBarText = text;
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.login();
      }
    },

    async login() {
      this.isBusy = true;
      await this.$store
        .dispatch('auth/login', this.credentials)
        .then((res) => {
          if (res.status == 200) {
            this.enableSnackbar('Logged in Successfully!');
            this.$router.push('/dashboard');
          }
        })
        .catch((err) => {
          this.enableSnackbar(err.response.data.message);
          this.$refs.form.reset();
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>

<style></style>
