import axios from 'axios';

const state = {
  _capsules: [],
  _categories: [],
  _langCategories: [],
  _languages: [],
  _uploaded: '',
  _capsuleDetail: {},
};

const mutations = {
  SET_CATEGORIES: (state, categories) => {
    state._categories = categories;
  },

  SET_LANG_CATEGORIES: (state, langCategories) => {
    state._langCategories = langCategories;
  },

  SET_LANGUAGES: (state, languages) => {
    state._languages = languages;
  },

  SET_CAPSULES: (state, capsules) => {
    state._capsules = capsules;
  },

  ADD_CAPSULE: (state, capsule) => {
    state._capsules = [capsule, ...state._capsules];
  },

  UPLOADED: (state, uploaded) => {
    state._uploaded = uploaded;
  },

  REMOVE_CAPSULE: (state, id) => {
    state._capsules = state._capsules.filter((c) => c.id != id);
  },

  UPDATE_LIST: (state, updates) => {
    state._capsules = state._capsules.map((c) =>
      c.id == updates.id ? updates : c
    );
  },

  SET_CAPSULE_DETAIL: (state, capsule) => {
    state._capsuleDetail = capsule;
  },
};

const actions = {
  retrieveCapsules: async ({ commit }) => {
    return axios
      .get('/news-videos')
      .then((res) => {
        commit('SET_CAPSULES', res.data);
        return res;
      })
      .catch((err) => console.log(err));
  },

  retrieveCategories: async ({ commit }) => {
    return axios
      .get('/categories')
      .then((res) => {
        commit('SET_CATEGORIES', res.data);
      })
      .catch((err) => console.log(err));
  },

  retrieveCategoriesByLang: async ({ commit }, language) => {
    return axios
      .get(`/categories/${language}/category`)
      .then((res) => {
        commit('SET_LANG_CATEGORIES', res.data);
        return res;
      })
      .catch((err) => console.log(err));
  },

  retrieveLanguages: async ({ commit }) => {
    return axios
      .get('/languages')
      .then((res) => {
        commit('SET_LANGUAGES', res.data);
      })
      .catch((err) => console.log(err));
  },

  uploadVideo: async ({ commit }, video) => {
    commit('UPLOADED', 0);

    let newVideo = new FormData();
    newVideo.append('file', video);

    return axios
      .post('/news-videos/upload', newVideo, {
        onUploadProgress: function(e) {
          commit('UPLOADED', Math.round((e.loaded / e.total) * 100));
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  },

  uploadThumbnail: async ({ commit }, thumbnail) => {
    commit('UPLOADED', 0);

    let videoThumbnail = new FormData();
    videoThumbnail.append('file', thumbnail);

    return axios
      .post('/news-videos/thumbnail/upload', videoThumbnail, {
        onUploadProgress: function(e) {
          commit('UPLOADED', Math.round((e.loaded / e.total) * 100));
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  },

  createCapsule: async ({ commit }, capsule) => {
    return axios
      .post('/news-videos', capsule)
      .then((res) => {
        commit('ADD_CAPSULE', res.data);
        return res;
      })
      .catch((err) => console.log(err));
  },

  deleteCapsule: async ({ commit }, id) => {
    try {
      const res = await axios.delete(`/news-videos/${id}`);
      if (res.status == 200) commit('REMOVE_CAPSULE', id);
      return res;
    } catch (error) {
      return error;
    }
  },

  updateCapsule: async ({ commit }, updates) => {
    try {
      const res = await axios.put(`/news-videos/${updates.id}`, updates);
      if (res.status == 200) commit('UPDATE_LIST', updates);
      return res;
    } catch (error) {
      return error;
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
