import axios from "axios";

const state = {
  _languages: [],
};

const actions = {
  retrieveLanguages: async ({ commit }) => {
    return axios
      .get("/languages")
      .then((res) => {
        commit("SET_LANGUAGES", res.data);
        return res;
      })
      .catch((err) => console.log(err));
  },

  createLanguage: async ({ commit }, language) => {
    return axios
      .post("/languages", language)
      .then((res) => {
        commit("ADD_LANGUAGE", res.data);
        return res;
      })
      .catch((err) => console.log(err));
  },

  deleteLanguage: async ({ commit }, language) => {
    return axios
      .delete(`/languages?language=${language}`)
      .then((res) => {
        if (res.status === 200) commit("REMOVE_LANGUAGE", language);
        return res;
      })
      .catch((err) => console.log(err));
  },
};

const mutations = {
  SET_LANGUAGES: (state, languages) => {
    state._languages = languages;
  },

  ADD_LANGUAGE: (state, language) => {
    state._languages = [language, ...state._languages];
  },

  REMOVE_LANGUAGE: (state, language) => {
    state._languages = state._languages.filter((l) => l.name !== language);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
