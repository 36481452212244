import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import auth from "./modules/auth";
import category from "./modules/category";
import capsules from "./modules/capsules";
import language from "./modules/language";
import users from "./modules/users";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "poc_persist",
});

export default new Vuex.Store({
  modules: {
    auth,
    category,
    capsules,
    language,
    users,
  },
  plugins: [vuexLocal.plugin],
});
