import axios from 'axios';

const state = {
  _categories: [],
  _langCategories: [],
  _languages: [],
};

const actions = {
  retrieveLanguages: async ({ commit }) => {
    return axios
      .get('/languages')
      .then((res) => {
        commit('SET_LANGUAGES', res.data);
      })
      .catch((err) => console.log(err));
  },
  retrieveCategories: async ({ commit }) => {
    return axios
      .get('/categories')
      .then((res) => {
        commit('SET_CATEGORIES', res.data);
        return res;
      })
      .catch((err) => console.log(err));
  },
  retrieveCategoriesByLang: async ({ commit }, language) => {
    return axios
      .get(`/categories/${language}/category`)
      .then((res) => {
        commit('SET_LANG_CATEGORIES', res.data);
        return res;
      })
      .catch((err) => console.log(err));
  },
  createCategory: async ({ commit }, category) => {
    return axios
      .post('/categories', category)
      .then((res) => {
        commit('ADD_CATEGORY', res.data);
        return res;
      })
      .catch((err) => console.log(err));
  },
  deleteCategory: async ({ commit }, categoryId) => {
    return axios
      .delete(`/categories?categoryId=${categoryId}`)
      .then((res) => {
        if (res.status === 200) commit('REMOVE_CATEGORY', categoryId);
        return res;
      })
      .catch((err) => console.log(err));
  },
};

const mutations = {
  SET_LANGUAGES: (state, languages) => {
    state._languages = languages;
  },

  SET_CATEGORIES: (state, categories) => {
    state._categories = categories;
  },

  SET_LANG_CATEGORIES: (state, langCategories) => {
    state._langCategories = langCategories;
  },

  ADD_CATEGORY: (state, category) => {
    state._categories = [category, ...state._categories];
  },

  REMOVE_CATEGORY: (state, categoryId) => {
    state._categories = state._categories.filter((c) => c.id !== categoryId);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
