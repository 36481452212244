import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from "../store/index";
import Cookies from 'js-cookie';

import Login from '../views/Login.vue';

Vue.use(VueRouter);

const routes = [
  { path: '', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    meta: { guest: true },
    component: Login,
  },
  {
    path: '/welcome',
    name: 'welcome',
    meta: { guest: true },
    component: () =>
      import(/* webpackChunkName: "welcome" */ '@/views/Welcome.vue'),
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { auth: true },
    redirect: { name: 'capsules' },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),

    children: [
      {
        path: 'languages',
        name: 'languages',
        component: () =>
          import(/* webpackChunkName: "capsules" */ '@/views/Languages.vue'),
      },
      {
        path: 'categories',
        name: 'categories',
        component: () =>
          import(/* webpackChunkName: "capsules" */ '@/views/Categories.vue'),
      },
      {
        path: 'capsules',
        name: 'capsules',
        component: () =>
          import(
            /* webpackChunkName: "capsules" */ '@/views/capsules/Capsules.vue'
          ),
      },
      {
        path: 'ad-videos',
        name: 'ad-videos',
        component: () =>
          import(
            /* webpackChunkName: "ad-videos" */ '@/views/ad-videos/AdVideos.vue'
          ),
      },
      {
        path: 'ad-images',
        name: 'ad-images',
        component: () =>
          import(
            /* webpackChunkName: "ad-images" */ '@/views/ad-images/AdImages.vue'
          ),
      },
      {
        path: 'users',
        name: 'users',
        component: () =>
          import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
      },
    ],
  },

  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { guest: true },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '@/views/ForgotPassword.vue'
      ),
  },
  {
    path: '/registrationConfirm/:token',
    name: 'confirm-registration',
    props: true,
    meta: { guest: true },
    component: () =>
      import(
        /* webpackChunkName: "confirm-registration" */ '@/views/ConfirmRegistration.vue'
      ),
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    props: true,
    meta: { guest: true },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;

// // Admin Guard
// function adminGuard(to, from, next) {
//   if (store.getters["auth/loggedIn"] && store.getters["auth/isAdmin"]) next();
//   else next({ name: "home" });
// }
// Navigation Guard
router.beforeEach((to, from, next) => {
  var isAuthenticated = false;
  if (Cookies.get('accessToken')) isAuthenticated = true;

  if (to.matched.some((record) => record.meta.auth)) {
    if (!isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (isAuthenticated) {
      next('/dashboard');
    } else {
      next();
    }
  } else {
    next();
  }
});
