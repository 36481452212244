<template>
  <v-app-bar app class="navbar--header" dark>
    <div class="d-flex align-center " @click="$router.push('/')">
      <v-img
        :alt="COMMON.APP_TITLE"
        class="shrink mt-1"
        contain
        min-width="100"
        :src="require('@/assets/logo.png')"
        width="100"
      />
    </div>

    <v-spacer />

    <v-btn
      v-if="loggedIn"
      text
      class="font-weight-bold secondary--text"
      @click="logout"
    >
      <span class="mr-2 ">{{ COMMON.LOGOUT_LABEL }}</span>
      <v-icon>{{ ICON.LOGOUT }}</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
  import { mapGetters } from "vuex";
  import common from "@/commons/common";

  export default {
    data() {
      return {
        COMMON: common,
        ICON: common.ICON,
        url: process.env.VUE_APP_BASE_URL,
      };
    },

    computed: {
      ...mapGetters({ loggedIn: "auth/loggedIn" }),
    },

    methods: {
      logout() {
        this.$store.dispatch("auth/logout").then(() => this.$router.push("/"));
      },
    },
  };
</script>

<style></style>
