import axios from 'axios';
import Cookies from 'js-cookie';

const state = {
  _accessToken: null,
  _loggedInUser: {},
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state._accessToken = token;
    Cookies.set('accessToken', token, { expires: 1 });
  },

  SET_USER: (state, user) => {
    state._loggedInUser = user;
  },

  LOGOUT: (state) => {
    state._accessToken = null;
    state._loggedInUser = null;
    Cookies.remove('accessToken');
  },
};

const actions = {
  login: ({ commit }, credentials) => {
    return axios
      .post('/auth/authenticate', credentials)
      .then((res) => {
        commit('SET_TOKEN', res.data.accessToken);
        commit('SET_USER', res.data.user);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  },

  logout: ({ commit }) => {
    commit('LOGOUT');
    return true;
  },
};

const getters = {
  loggedIn: (state) => state._accessToken,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
