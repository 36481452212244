import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; //todo: thought this was upposed to be handled in server and not in client?
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
console.log(process.env.VUE_APP_BASE_URL);
Vue.prototype.$axios = axios;

axios.interceptors.request.use((config) => {
  const token = Cookies.get('accessToken');
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      Cookies.remove('accessToken');
      router.push({ name: 'login' });
    } else {
      return Promise.reject(error);
    }
  }
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
