const common = {
  // globals
  APP_TITLE: 'Newsaudible',

  // LABELS
  LOGIN_LABEL: 'Login',
  LOGOUT_LABEL: 'Logout',
  LABEL_FORGOT_PASSWORD: 'Forgot Password',
  LABEL_SEND_RESET_TOKEN: 'Send Reset Token',
  LABEL_RESET_PASSWORD: 'Reset Password',
  LABEL_CONFIRM_REGISTRATION: 'Confrim Registration',
  LABEL_VERIFY: 'Verify',
  LABEL_RESEND_TOKEN: 'Resend Token',

  // TITLES
  USERS_TITLE: 'Users',
  CAPSULES_TITLE: 'Capsules',

  //BUTTONS
  CLOSE_BTN: 'Close',
  CANCEL_BTN: 'Cancel',
  PREVIEW_BTN: 'Preview',

  // CAPSULES Menu
  CAPSULE_LABEL: 'Capsules',
  ADD_CAPSULE: 'Add Capsule',
  BROADCAST_LABEL_BTN: 'Broadcast',
  PREVIEW_CAPSULE: 'Preview Capsule',
  EDIT_CAPSULE: 'Edit Capsule',
  DELETE_CAPSULE: 'Delete Capsule',

  // LANGUAGE
  LANGUAGE_LABLE: 'Languages',
  ADD_LANGUAGE: 'Add Language',

  // CATEOGRY
  CATEGORY_LABEL: 'Categories',
  ADD_CATEGORY: 'Add Category',

  // AD VIDEOS
  LABEL_AD_VIDEOS: 'Ad Videos',
  ADD_AD_VIDEO: 'Add Ad Video',
  EDIT_AD_VIDEO: 'Edit Ad Video',

  // AD IMAGES
  LABEL_AD_IMAGES: 'Ad Images',
  ADD_AD_IMAGES: 'Add Ad Image',
  EDIT_AD_IMAGES: 'Edit Ad Image',

  // ICONS
  ICON: {
    USER_ACCOUNT: 'mdi-account',
    USER_PROFILE: 'mdi-account-outline',
    USER_MULTIPLE: 'mdi-account-multiple',
    ACTIVITY_BELL_RING: 'mdi-bell-ring',
    APPOINTMENT_CALENDAR: 'mdi-calendar-text',
    ACCOUNT_PASSWORD: 'mdi-lock-outline',
    VIDEO_PREVIEW: 'mdi-video-check-outline',
    VIDEO: 'mdi-video',
    IMAGE: 'mdi-image',
    LOGOUT: 'mdi-logout',

    ADD_PLUS: 'mdi-plus',
    CLOSE: 'mdi-close',
    SEARCH_MAGNIFY: 'mdi-magnify',
    ARROW_LEFT: 'mdi-chevron-left',
    ARROW_RIGHT: 'mdi-chevron-right',
    ARROW_UP: 'mdi-chevron-up',
    ARROW_DOWN: 'mdi-chevron-down',
    CALENDAR_CLOCK: 'mdi-calendar-clock',
    CALENDAR: 'mdi-calendar',
    CLOCK_OUTLINE: 'mdi-clock-outline',
    LOCATION_MARKER: 'mdi-map-marker-outline',
    DOTS_HORIZONTAL: 'mdi-dots-horizontal',
    EYE_VIEW: 'mdi-eye',

    EDIT_SQUARE: 'mdi-square-edit-outline',
    DELETE_TRASH: 'mdi-trash-can-outline',
    DELETE_VARIANT: 'mdi-delete-variant',

    CALL_PHONE: 'mdi-phone-outline',
    MEET_ACCOUNT: 'mdi-account-star-outline',
    TEXT_MESSAGE: 'mdi-comment-account-outline',

    PENDING_TIMER: 'mdi-timer-sand-full',
    CHECK_CIRCLE: 'mdi-check-circle',
    INFORMATION: 'mdi-information-outline',
  },
};

export default common;
