import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#efbd22',
        secondary: '#443414',
        accent: '#f0be21',
        error: '#b71c1c',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#8c9eff',
        chip_even: '#efc023',
        chip_odd: '#f6dd96',
        modaltext: '#ffffff',
      },
    },
  },
});
